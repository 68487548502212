import {NgModule} from '@angular/core';
import {HttpClientModule} from '@angular/common/http';
import {RouterModule} from '@angular/router';
import {MatDividerModule} from '@angular/material/divider';
import {MatIconModule} from '@angular/material/icon';
import {SearchModule} from 'app/layout/common/search/search.module';
import {EnterpriseLayoutComponent} from 'app/layout/layouts/horizontal/enterprise/enterprise.component';
import {MatButtonModule} from "@angular/material/button";
import {MatMenuModule} from "@angular/material/menu";

@NgModule({
    imports: [
        HttpClientModule,
        RouterModule,
        MatButtonModule,
        MatDividerModule,
        MatIconModule,
        MatMenuModule,
        SearchModule,
        EnterpriseLayoutComponent
    ],
    exports: [
        EnterpriseLayoutComponent
    ]
})
export class EnterpriseLayoutModule {
}
