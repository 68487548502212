import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {FormsModule, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {environment} from '@env/environment';
import {fuseAnimations} from '@fuse/animations';
import {FuseAlertComponent, FuseAlertType} from '@fuse/components/alert';
import {of, take} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {HandshakeParams} from '../../../handshake/models/handshakeParams';
import {HandshakeService} from '../../../handshake/services/handshake.service';
import {NgIf} from '@angular/common';
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {MatButtonModule} from "@angular/material/button";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
    selector: 'enter-msa-code',
    templateUrl: './enter-msa-code.component.html',
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
    standalone: true,
    imports: [NgIf, FuseAlertComponent, FormsModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule, MatButtonModule, MatProgressSpinnerModule]
})
export class EnterMsaCodeComponent implements OnInit {

    alert: { type: FuseAlertType; message: string } = {
        type: 'success',
        message: ''
    };

    accessFormGroup: UntypedFormGroup;
    showAlert: boolean = false;

    /**
     * Constructor
     */
    constructor(
        private _formBuilder: UntypedFormBuilder,
        private handshakeService: HandshakeService,
        private snackbar: MatSnackBar,
        private router: Router
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Create the form
        this.accessFormGroup = this._formBuilder.group({
            accessCode: [null, [Validators.required]]
        });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Send the reset link
     */
    handShake(): void {
        if (this.accessFormGroup.invalid) {
            return;
        }
        this.accessFormGroup.disable();
        this.resetAlert();
        const shortenUrl = this.accessFormGroup.get('accessCode')?.value as string;
        const handshakeParams: HandshakeParams = {
            shortenUrl,
            pool: environment.pool,
            customer: environment.customer
        };

        this.handshakeService.checkShortenUrl(handshakeParams)
            .pipe(
                map((res) => {
                    console.log('handshake success --> res --> ', res);
                    this.handshakeService.selected = res;
                    // this.handshakeService.selected.shortenUrl = shortenUrlParam;
                    return true;
                }),
                take(1),
                catchError((err) => {
                    // Getting the Error details.
                    const code = err.code;
                    const message = err.message;
                    const details = err.details;
                    console.log('Error: ', err);
                    console.log('err.code: ', err.code);
                    console.log('err.message: ', err.message);
                    console.log('err.details: ', err.details);


                    switch (code) {
                        case 'functions/failed-precondition':
                            this.showErrorAlert('Ein Fehler ist aufgetreten: ' + (err?.message || ''));
                            break;
                        case 'functions/not-found':
                            this.showErrorAlert((err?.message || ''));
                            break;
                        case 'functions/unknown':
                            this.showErrorAlert('Ein Fehler ist aufgetreten: ' + (err?.message || ''));
                            break;
                        default:
                            this.showErrorAlert('Ein Fehler ist aufgetreten: ' + (err?.message || ''));
                            break;
                    }

                    this.accessFormGroup.enable();
                    return of(false);
                })
            ).subscribe((res) => {
            if (res) {
                return this.router.navigate([shortenUrl]);
            }
        });
    }

    resetAlert(): void {
        this.showAlert = false;
        this.alert = {
            type: 'error',
            message: ''
        };
    }

    showErrorAlert(message: string): void {
        this.alert = {
            type: 'error',
            message
        };
        this.showAlert = true;
    }

}
