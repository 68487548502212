import {NgModule} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';
import {FuseConfirmationService} from '@fuse/services/confirmation/confirmation.service';
import {FuseConfirmationDialogComponent} from '@fuse/services/confirmation/dialog/dialog.component';
import {CommonModule} from '@angular/common';
import {MatDialogModule} from "@angular/material/dialog";
import {MatButtonModule} from "@angular/material/button";

@NgModule({
    imports: [
        MatButtonModule,
        MatDialogModule,
        MatIconModule,
        CommonModule,
        FuseConfirmationDialogComponent
    ],
    providers: [
        FuseConfirmationService
    ]
})
export class FuseConfirmationModule {
    /**
     * Constructor
     */
    constructor(private _fuseConfirmationService: FuseConfirmationService) {
    }
}
