<div class="flex flex-col sm:flex-row items-center md:items-start sm:justify-center md:justify-start flex-auto min-w-0">
    <div
        class="relative hidden md:flex flex-auto items-center justify-center w-1/2 h-full p-16 lg:px-28 overflow-hidden bg-gray-800 dark:border-r">
        <!-- Background - @formatter:off -->
        <!-- Rings -->
        <svg class="absolute inset-0 pointer-events-none"
             height="100%" preserveAspectRatio="xMidYMax slice" viewBox="0 0 960 540" width="100%"
             xmlns="http://www.w3.org/2000/svg">
            <g class="text-gray-700 opacity-25" fill="none" stroke="currentColor" stroke-width="100">
                <circle cx="196" cy="23" r="234"></circle>
                <circle cx="790" cy="491" r="234"></circle>
            </g>
        </svg>
        <!-- Dots -->
        <svg class="absolute -top-16 -right-16 text-gray-700"
             fill="none" height="192" viewBox="0 0 220 192" width="220">
            <defs>
                <pattern height="20" id="837c3e70-6c3a-44e6-8854-cc48c737b659" patternUnits="userSpaceOnUse" width="20"
                         x="0" y="0">
                    <rect fill="currentColor" height="4" width="4" x="0" y="0"></rect>
                </pattern>
            </defs>
            <rect fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)" height="192" width="220"></rect>
        </svg>
        <!-- @formatter:on -->
        <!-- Content -->
        <div class="z-10 relative w-full max-w-2xl">
            <div class="text-7xl font-bold leading-none text-gray-100">
                <div>Willkommen bei Nahaus.de</div>
                <div>für Mietinteressenten</div>
            </div>
            <div class="mt-6 text-lg tracking-tight leading-6 text-gray-400">
                Nahaus ist eine digitale und automatisierte Immobilienverwaltung Software für privat Vermieter,
                Immobilienverwalter, Immobilienmakler und Hausverwaltungen.
            </div>

            <div class="mt-6 text-lg tracking-tight leading-6 text-gray-400">
                Diese Seite von Nahaus dient als Schnittstelle zwischen dem Vermieter und den Mietinteressenten indem
                die Mietinteressenten in 8 einfachen Schritten eine digitale Mieterselbstauskunft an dem Vermieter ganz
                bequem online senden können.
            </div>

            <div class="mt-6 text-lg tracking-tight leading-6 text-gray-400">
                Nachdem Sie die digitale Mieterselbstauskunft ausgefüllt haben und verschickt, können Sie jeder Zeit den
                Bearbeitungsstand dieser verfolgen.
            </div>

            <div class="mt-6 text-lg tracking-tight leading-6 text-gray-400">
                Damit Sie starten können, geben Sie den Zugangscode den Ihren Vermieter Ihnen zu verfügung gestellt hat.
            </div>
            <div class="flex items-center mt-8">
                <div class="flex flex-0 items-center -space-x-1.5">
                    <img
                        class="flex-0 w-10 h-10 rounded-full ring-4 ring-offset-1 ring-gray-800 ring-offset-gray-800 object-cover"
                        src="assets/images/avatars/female-18.jpg">
                    <img
                        class="flex-0 w-10 h-10 rounded-full ring-4 ring-offset-1 ring-gray-800 ring-offset-gray-800 object-cover"
                        src="assets/images/avatars/female-11.jpg">
                    <img
                        class="flex-0 w-10 h-10 rounded-full ring-4 ring-offset-1 ring-gray-800 ring-offset-gray-800 object-cover"
                        src="assets/images/avatars/male-09.jpg">
                    <img
                        class="flex-0 w-10 h-10 rounded-full ring-4 ring-offset-1 ring-gray-800 ring-offset-gray-800 object-cover"
                        src="assets/images/avatars/male-16.jpg">
                </div>
                <div class="ml-4 font-medium tracking-tight text-gray-400">
                    Mehr als 5.000 Personen haben bereits eine Mieterselbstauskunft an ihren Vermieter über Nahaus
                    gesendet, Sie sind an der Reihe
                </div>
            </div>
        </div>
    </div>
    <div
        class="md:flex md:items-center md:justify-start w-full sm:w-auto md:h-full md:w-1/2 py-8 px-4 sm:p-12 md:p-16 sm:rounded-2xl md:rounded-none sm:shadow md:shadow-none sm:bg-card">
        <div class="w-full max-w-80 sm:w-80 mx-auto sm:mx-0">
            <!-- Logo -->
            <div class="w-12">
                <img src="assets/logo/logo_single.svg">
            </div>

            <!-- Title -->
            <div class="mt-8 text-4xl font-extrabold tracking-tight leading-tight">Geben Sie den Zugangscode ein</div>
            <div class="mt-0.5 font-medium">um den Mieterselbstauskunft Portal beizutreten</div>

            <!-- Alert -->
            <fuse-alert
                *ngIf="showAlert"
                [@shake]="alert.type === 'error'"
                [appearance]="'outline'"
                [showIcon]="false"
                [type]="alert.type"
                class="mt-8 -mb-4">
                {{ alert.message }}
            </fuse-alert>

            <!-- Forgot password form -->
            <form
                #forgotPasswordNgForm="ngForm"
                [formGroup]="accessFormGroup"
                class="mt-8">

                <!-- Email field -->
                <mat-form-field class="w-full">
                    <mat-label>Zugangscode</mat-label>
                    <input
                        [formControlName]="'accessCode'"
                        id="code"
                        placeholder="X9cO"
                        matInput>
                    <mat-hint>Der Zugangscode wird vom Ihrem Vermieter zu Verfügung gestellt</mat-hint>
                    <mat-error *ngIf="accessFormGroup.get('accessCode').hasError('required')">
                        Bitte geben Sie einen gültigen Zugangscode ein
                    </mat-error>
                </mat-form-field>

                <!-- Submit button -->
                <button
                    (click)="handShake()"
                    [color]="'primary'"
                    [disabled]="accessFormGroup.disabled"
                    class="fuse-mat-button-large w-full mt-10"
                    mat-flat-button>
                    <span *ngIf="!accessFormGroup.disabled">
                        Weiter
                    </span>
                    <mat-progress-spinner
                        *ngIf="accessFormGroup.disabled"
                        [diameter]="24"
                        [mode]="'indeterminate'"></mat-progress-spinner>
                </button>

                <!-- Form footer -->
                <div class="mt-8 text-md font-medium text-secondary">
                    <a class="ml-1 text-primary-500 hover:underline" href="https://nahaus.de/agb"
                       target="_blank">Allgemeine Geschäftsbedingungen
                    </a>
                </div>

            </form>
        </div>
    </div>
</div>
