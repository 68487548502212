import {NativeDateAdapter} from '@angular/material/core';
import {registerPlugin} from 'filepond';
import FilePondPluginFileRename from 'filepond-plugin-file-rename';

import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
// import and register filepond file type validation plugin
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilepondPluginImageEdit from 'filepond-plugin-image-edit';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilepondPluginImagePreview from 'filepond-plugin-image-preview';

registerPlugin(FilePondPluginFileValidateType, FilepondPluginImageEdit, FilepondPluginImagePreview, FilePondPluginFileValidateSize, FilePondPluginImageExifOrientation, FilePondPluginFileRename);


export class CustomDateAdapter extends NativeDateAdapter {
    parse(value: any): Date | null {
        const currentDate = new Date();
        let year: number = currentDate.getFullYear();
        let month: number = currentDate.getMonth();
        let day: number = currentDate.getDate();

        if ((typeof value === 'string') &&
            ((value.indexOf('/') > -1) || (value.indexOf('.') > -1) || (value.indexOf('-') > -1))) {

            const str = value.split(/[\./-]/);

            day = !!str[0] ? +str[0] : day;
            month = !!str[1] ? +str[1] - 1 : month;
            year = !!str[2] ?
                // If year is less than 3 digit long, we add 2000.
                +str[2].length <= 3 ? +str[2] + 2000 : +str[2] : year;

            return new Date(year, month, day);
        }
    }
}


