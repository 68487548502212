import {NgModule} from '@angular/core';
import {HttpClientModule} from '@angular/common/http';
import {RouterModule} from '@angular/router';
import {MatDividerModule} from '@angular/material/divider';
import {MatIconModule} from '@angular/material/icon';
import {SearchModule} from 'app/layout/common/search/search.module';
import {CompactLayoutComponent} from 'app/layout/layouts/vertical/compact/compact.component';
import {MatButtonModule} from "@angular/material/button";
import {MatMenuModule} from "@angular/material/menu";

@NgModule({
    imports: [
        HttpClientModule,
        RouterModule,
        MatButtonModule,
        MatDividerModule,
        MatIconModule,
        MatMenuModule,
        SearchModule,
        CompactLayoutComponent
    ],
    exports: [
        CompactLayoutComponent
    ]
})
export class CompactLayoutModule {
}
