<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Navigation -->
<ng-container *ngIf="isScreenSmall">
    <fuse-vertical-navigation
            class="dark bg-gray-900 print:hidden"
            [mode]="'over'"
            [name]="'mainNavigation'"
            [navigation]="navigation.default"
            [opened]="false">
        <!-- Navigation header hook -->
        <ng-container fuseVerticalNavigationContentHeader *ngIf="!this.handshakeService?.selected?.logo;else smallScreenUserLogo">
            <!-- Logo -->
            <div class="flex items-center h-20 pt-6 px-8 mb-4">
                <img
                        class="w-24"
                        src="assets/logo/logo_light_2.svg"
                        alt="Logo image">
            </div>
        </ng-container>
        <ng-template fuseVerticalNavigationContentHeader #smallScreenUserLogo>
            <!-- Logo -->
            <div class="flex items-center h-20 pt-6 px-8 mb-4">
                <img
                        class="w-12"
                        [src]="this.handshakeService?.selected?.logo?.url"
                        alt="Logo image">
            </div>
        </ng-template>
    </fuse-vertical-navigation>
</ng-container>

<!-- Wrapper -->
<div class="flex flex-col flex-auto items-center w-full min-w-0 bg-gray-200 dark:bg-card">

    <!-- Header -->
    <div class="relative flex flex-col flex-0 justify-center w-full h-16 sm:h-20 md:h-36 overflow-hidden z-49 shadow dark:shadow-none print:hidden">
        <!-- Top bar -->
        <div class="relative dark flex flex-auto justify-center w-full px-4 md:px-8 bg-gray-800 dark:bg-gray-900">
            <div class="flex items-center w-full max-w-360 h-16 sm:h-20">
                <!-- Logo -->

                    <div class="flex items-center" *ngIf="!isScreenSmall && !this.handshakeService?.selected?.logo; else userLogo">
                        <img
                                class="w-24"
                                src="assets/logo/logo_light_2.svg"
                                alt="Logo image">

                    </div>
                    <ng-template class="flex items-center" #userLogo>
                        <img
                                class="w-12"
                                [src]="this.handshakeService?.selected?.logo?.url"
                                alt="Logo image">

                    </ng-template>
                <!-- Navigation toggle button -->
                <ng-container *ngIf="isScreenSmall">
                    <button
                            mat-icon-button
                            (click)="toggleNavigation('mainNavigation')">
                        <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
                    </button>
                </ng-container>
                <!-- Components -->
                <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
                    <languages></languages>
                    <fuse-fullscreen class="hidden md:block"></fuse-fullscreen>
                    <search *ngIf="false" [appearance]="'bar'"></search>
                    <shortcuts *ngIf="false"></shortcuts>
                    <messages *ngIf="false"></messages>
                    <notifications *ngIf="false"></notifications>
                    <button *ngIf="false"
                            class="lg:hidden"
                            mat-icon-button
                            (click)="quickChat.toggle()">
                        <mat-icon [svgIcon]="'heroicons_outline:chat-alt-2'"></mat-icon>
                    </button>
                    <user *ngIf="false"></user>
                </div>
            </div>
        </div>
        <!-- Bottom bar -->
        <ng-container *ngIf="!isScreenSmall">
            <div class="flex flex-auto justify-center px-4 md:px-8 bg-card dark:bg-gray-700">
                <div class="relative flex items-center w-full max-w-360 h-16">
                    <fuse-horizontal-navigation
                            class="-mx-4"
                            [name]="'mainNavigation'"
                            [navigation]="navigation.horizontal"></fuse-horizontal-navigation>
                </div>
            </div>
        </ng-container>
    </div>

    <!-- Content -->
    <div class="flex flex-auto justify-center w-full sm:p-6 md:p-8">
        <div class="flex flex-col flex-auto w-full sm:max-w-360 sm:shadow-lg sm:rounded-lg sm:overflow-hidden bg-default">
            <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
                 Otherwise, layout changes won't be registered and the view won't be updated! -->
            <router-outlet *ngIf="true"></router-outlet>
        </div>
    </div>

    <!-- Footer -->
    <div class="relative flex flex-0 justify-center w-full px-6 md:px-8 z-49 border-t bg-card print:hidden">
        <div class="flex items-center w-full max-w-360 h-14 sm:h-20">
            <span class="font-medium text-secondary">Nahaus.de &copy; {{currentYear}}</span>
            <div class="flex justify-end space-x-4 w-full">
                <a href="https://nahaus.de/impressum" mat-button target="_blank">Impressum</a>
                <a href="https://nahaus.de/agb" mat-button target="_blank">AGB</a>
                <a href="https://nahaus.de/datenschutz" mat-button target="_blank">DSGVO</a>
            </div>
        </div>
    </div>

</div>

<!-- Quick chat -->
<quick-chat *ngIf="false" #quickChat="quickChat"></quick-chat>
