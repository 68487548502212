// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import { env } from '@env/.env';

export const environment = {
  production: false,
  pool: true,
  customer: 'pool',
  version: env.npm_package_version + '-dev-p',
  googleMapsApiKey: 'AIzaSyBUatcv92TBlAxy-XHGQavV6u-o0FkQrx4',
  reCaptchaV3Key: '6LeJaloiAAAAAE3dY_eNuOMqiim-YcdlprbKP7uP',
  firebase: {
    apiKey: 'AIzaSyCnfoF0xyZI97SjEUgEM8_ppval1_JNn3w',
    authDomain: 'dev-mieter-nahaus-de.firebaseapp.com',
    projectId: 'dev-mieter-nahaus-de',
    storageBucket: 'dev-mieter-nahaus-de.appspot.com',
    messagingSenderId: '792334435313',
    appId: '1:792334435313:web:e760fa682f8b530d41fa50',
    measurementId: 'G-JBTM737EMY'
  },
  adminProjectId: 'dev-nahaus-de'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
