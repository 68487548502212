import {Route} from '@angular/router';
import {registerLocaleData} from '@angular/common';
import localeDe from '@angular/common/locales/de';
import {environment} from '@env/environment';
import {EnterMsaCodeComponent} from '@msa/components/enter-msa-code/enter-msa-code.component';
import {InitialDataResolver} from 'app/app.resolvers';
import {LayoutComponent} from 'app/layout/layout.component';
import {HandshakeGuard} from './modules/handshake/guards/handshake.guard';
import {TRANSLOCO_SCOPE} from '@ngneat/transloco';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {CustomDateAdapter} from '@shared/shared.module';
import {Platform} from '@angular/cdk/platform';
import moment from 'moment';
import { TicketingGuard } from './modules/handshake/guards/ticketing.guard';
import { TicketComponent } from '@msa/components/ticket/ticket/ticket/ticket.component';


registerLocaleData(localeDe);

export const appRoutes: Route[] = [

  // Redirect empty path to '/mieterselbstauskunft'
  {
    path: '',
    pathMatch: 'full',
    redirectTo: !environment?.pool && environment?.customer === 'jug' ? 'jug' : 'code-eingeben'
  },

  // Other routes
  {
    path: 'fehler/404',
    loadChildren: () => import('app/modules/pages/error-404/error-404.module').then(m => m.Error404Module)
  },
  {
    path: 'fehler/500',
    loadChildren: () => import('app/modules/pages/error-500/error-500.module').then(m => m.Error500Module)
  },
  {
    path: 'accessDenied',
    loadChildren: () => import('app/modules/pages/access-denied/access-denied.module').then(m => m.AccessDeniedModule)
  },

  {
    path: '',
    // canActivate: [NoAuthGuard],
    // canActivateChild: [NoAuthGuard],
    pathMatch:'full',
    component: LayoutComponent,
    data: {
      layout: 'empty'
    },
    children: [
      {
        path: 'code-eingeben',
        component: EnterMsaCodeComponent
      }
    ]
  },

  // Route for :shortenUrl/mieterselbstauskunft

  {
    path: 'jug',
    component: LayoutComponent,
    resolve: {
      initialData: InitialDataResolver
    },
    children: [
      {
        path: '',
        loadChildren: () => import('app/modules/msa/msa.routing').then(m => m.msaRoutes),
        providers: [
          { provide: TRANSLOCO_SCOPE, useValue: 'msa' },
          { provide: DateAdapter, useClass: CustomDateAdapter, deps: [MAT_DATE_LOCALE, Platform] },
          {
            provide: MAT_DATE_FORMATS,
            useValue: {
              parse: {
                dateInput: moment.ISO_8601
              },
              display: {
                dateInput: 'LL',
                monthYearLabel: 'MMM YYYY',
                dateA11yLabel: 'LL',
                monthYearA11yLabel: 'MMMM YYYY'
              }
            }
          }
        ]
      }
    ]
  },

  {
    path: ':shortenUrl',
    canActivate: [HandshakeGuard],
    component: LayoutComponent,
    resolve: {
      initialData: InitialDataResolver
    },
    children: [
      {
        path: '',
        loadChildren: () => import('app/modules/msa/msa.routing').then(m => m.msaRoutes),
        providers: [
          { provide: TRANSLOCO_SCOPE, useValue: 'msa' },
          { provide: DateAdapter, useClass: CustomDateAdapter, deps: [MAT_DATE_LOCALE, Platform] },
          {
            provide: MAT_DATE_FORMATS,
            useValue: {
              parse: {
                dateInput: moment.ISO_8601
              },
              display: {
                dateInput: 'LL',
                monthYearLabel: 'MMM YYYY',
                dateA11yLabel: 'LL',
                monthYearA11yLabel: 'MMMM YYYY'
              }
            }
          }
        ]
      }
    ]
  },
 


  
  // Fallback for unknown routes
  { path: '**', redirectTo: 'fehler/404' }
];
