/* tslint:disable:max-line-length */
import {FuseNavigationItem} from '@fuse/components/navigation';
import {environment} from "@env/environment";


const msaNavItem: FuseNavigationItem = {
    id: 'msa',
    title: 'Mieterselbstauskunft',
    type: 'basic',
    icon: 'mat_outline:assignment_ind',
    link: 'mieterselbstauskunft',
    tooltip: 'Digitale Mieterselbstauskunft senden',
    queryParamsHandling: 'preserve'
};
const ticketNavItem: FuseNavigationItem = {
    id: 'ticket',
    title: `${'Ticket'}`,
    type: 'basic',
    icon: 'heroicons_outline:plus',
    link: 'ticket',
    tooltip: 'Schaden und Mängel melden',
    queryParamsHandling: 'preserve',
    badge: {
        title: 'beta',
        classes: 'ml-2 px-2 bg-primary text-on-primary rounded'
    },
    hidden: (() => environment.production)
};

export const defaultNavigation: FuseNavigationItem[] = [
    msaNavItem,
    ticketNavItem,
];
export const compactNavigation: FuseNavigationItem[] = [
    msaNavItem,
    ticketNavItem,
];
export const futuristicNavigation: FuseNavigationItem[] = [
    msaNavItem,
    ticketNavItem,
];
export const horizontalNavigation: FuseNavigationItem[] = [
    msaNavItem,
    ticketNavItem,
];

